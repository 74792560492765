import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { Button, Image } from '@thd-olt-component-react/core-ui';
import { func, oneOf, string } from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import '../../styles/savings-center-promos.style.scss';

class SavingsCenterPromos extends Component {

  async componentDidMount() {
    const { channel } = this.props;
    if (channel === 'mobile') {
      const { default: overlayObj } = await import('thd-overlay');
      this.Overlay = overlayObj;
      if (typeof window !== 'undefined') {
        const div = document.createElement('div');
        ReactDom.render(this.tooltipContent(), div);
        this.mobileOverlay = new this.Overlay({
          speed: '500ms',
          position: 'static',
          useContent: true,
          channel: this.props.channel,
          header: true,
          htmlNode: div,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.Overlay && this.Overlay.destroy) {
      this.Overlay.destroy();
    }
  }

  mobileToolTipContent = (content) => {
    return (
      <div>
        <div className="savings-center-promo__mobile--content">{content}</div>
      </div>
    );
  }

  tooltipContent = () => {
    const { channel } = this.props;
    const content = 'Save when you buy multiple. See final price in cart.';
    return (
      <div>
        {channel === 'mobile' ? this.mobileToolTipContent(content) : content}
      </div>
    );
  }

  /* adding function for checking if it's a tablet since
   we do not have a channel property to determine if it is a tablet */
  isTablet = () => {
    if (typeof window !== 'undefined' && window.navigator && window.navigator.userAgent) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
      return isTablet;
    }
    return false;
  };

  openMobileOverlay = () => {
    this.mobileOverlay.open();
  };

  renderMobileView = () => {
    const { savingCenterPromoMessage } = this.props;
    return (
      <div className="savings-center-promo__mobile">
        <Button link onClick={this.openMobileOverlay}>
          <span className="savings-center-promo__mobile--bold">
            {savingCenterPromoMessage}
          </span>
          <span className="savings-center-promo__mobile--link">
            See Details
          </span>
        </Button>
      </div>
    );
  }

  renderDesktopView = (tooltipId) => {
    const { channel, onSavingsCenterToggle, savingCenterPromoMessage } = this.props;
    let onMouseHoverFunc;
    if (this.isTablet()) {
      onMouseHoverFunc = () => {};
    } else {
      onMouseHoverFunc = onSavingsCenterToggle;
    }
    return (
      <div className="savings-center-promo">
        <div>
        <div className="savings-center-promo--label">
          <Button
            link
            small
            onMouseEnter={onMouseHoverFunc}
            onMouseLeave={onMouseHoverFunc}
            className="savings-center-promo--bold"
          >
            {savingCenterPromoMessage}
          </Button>
        </div>
        <div className="savings-center-promo--link">
          <Tooltip
            channel={channel}
            closeButton
            content={this.tooltipContent()}
            maxWidth={275}
            placement="bottom"
          >
            <button
              onMouseEnter={onMouseHoverFunc}
              onMouseLeave={onMouseHoverFunc}
              onClose={onSavingsCenterToggle}
              onClick={onSavingsCenterToggle}
              id={tooltipId}
            >
              See Details
            </button>
          </Tooltip>
        </div>
        </div>
      </div>
    );
  }

  render() {
    const { channel, itemId } = this.props;
    const tooltipId = `savings-center-tooltip-${itemId}`;
    return (
      <div>
        { channel === 'mobile' ? this.renderMobileView() : this.renderDesktopView(tooltipId) }
      </div>
    );
  }
}

SavingsCenterPromos.propTypes = {
  /**
   * generates a unique id for the tooltip button
   */
  itemId: string,
  /**
   * device type
   */
  channel: oneOf(['desktop', 'mobile']),
  /**
   * callback for savings center clicks
   */
  onSavingsCenterToggle: func,
  savingCenterPromoMessage: string,
};

SavingsCenterPromos.defaultProps = {
  itemId: '',
  channel: 'desktop',
  onSavingsCenterToggle: () => {},
  savingCenterPromoMessage: ''
};

export default SavingsCenterPromos;
