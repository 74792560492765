import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Image } from '@thd-olt-component-react/core-ui';
import { Typography } from '@one-thd/sui-atomic-components';
import styles from '../../styles/preferred-pricing.module.scss';
import { LAYOUT_TYPES } from '../../utils/utils';

const cx = classNames.bind(styles);

const getPreferredPricingDetails = () => {
  return (
    <div>
      <span>
        As a Pro Xtra member, you are qualified for&nbsp;
        <a className="u__default-link" href="https://www.homedepot.com/c/pro_preferred_pricing">Preferred Pricing</a>
        .
      </span>
    </div>
  );
}

export const PreferredPricingBadge = ({
  channel,
  type,
  align,
  showPreferredPricingBadgeToolTip,
  isListView }) => {

  const [disableHoverTooltip, setDisableHoverTooltip] = useState(false);

  const isMobile = channel === 'mobile';

  const preferredPricingDetails = getPreferredPricingDetails();

  let preferredPricingWrapperClasses = '';
  let flexClasses = 'sui-flex sui-items-center sui-content-center';
  if (type === LAYOUT_TYPES.DETAILED) {
    preferredPricingWrapperClasses = cx('preferred-pricing__wrapper preferred-pricing__wrapper--side-padding');
  } else if (align === 'end' || (isListView && isMobile)) {
    flexClasses = 'sui-flex sui-justify-end';
  }

  const badgeSize = type === LAYOUT_TYPES.DETAILED ? 24 : 16;

  const typeVariant = type === LAYOUT_TYPES.DETAILED ? 'body-lg' : 'body-xs';

  return (
    <div className={preferredPricingWrapperClasses}>
      <div className={flexClasses}>
        <div className='sui-mr-2'>
          <Image
            className={cx("preferred-pricing__badge")}
            src="https://assets.thdstatic.com/images/v1/preferred-pricing-icon.svg"
            width={badgeSize}
            height={badgeSize}
            alt="Preferred Pricing Badge"
            title="Preferred Pricing" />
        </div>
        <Typography
          variant={typeVariant}
          weight="bold">
          PREFERRED PRICING
        </Typography>
        {showPreferredPricingBadgeToolTip && !isListView && (
          <div className='sui-ml-2'>
            {isMobile && (
              <Tooltip
                channel={channel}
                content={preferredPricingDetails}
                interactive
              >
                <Image
                  className={cx("preferred-pricing__info-icon")}
                  src="https://assets.thdstatic.com/images/v1/info.svg"
                  alt="preferred pricing info icon"
                />
              </Tooltip>
            )}
            {!isMobile && (
              <Tooltip
                channel={channel}
                content={preferredPricingDetails}
                interactive
                maxWidth={300}
                disableTooltip={disableHoverTooltip}
                placement="top"
              >
                <div>
                  <Tooltip
                    channel={channel}
                    closeButton
                    content={preferredPricingDetails}
                    interactive
                    maxWidth={320}
                    visible={disableHoverTooltip}
                    placement="top"
                  >
                    <div>
                      <Image
                        className={cx("preferred-pricing__info-icon")}
                        src="https://assets.thdstatic.com/images/v1/info.svg"
                        alt="preferred pricing info icon"
                        onClick={() => setDisableHoverTooltip(!disableHoverTooltip)}
                        onClose={() => setDisableHoverTooltip(false)}
                      />
                    </div>
                  </Tooltip>
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>


    </div>
  );
};

PreferredPricingBadge.propTypes = {
  /**
  * The type of channel ie; Desktop/Mobile
  */
  channel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  align: PropTypes.string,
  showPreferredPricingBadgeToolTip: PropTypes.bool
};

PreferredPricingBadge.defaultProps = {
  align: 'start',
  showPreferredPricingBadgeToolTip: false
};
